import React, { useState } from 'react';
import { notification } from 'antd';

export default function JoinForm() {
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, msg) => {
    api[type]({
      message: type,
      description:msg,
      duration: 2
    });
  };

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    const form = document.getElementById("myForm");
    const formData = new FormData(form);

    formData.append('access_key', '06f3ed00-62e6-4f69-b0fb-33859ba46581');
    formData.append("subject", "New Applicant");
    // const file = document.getElementById("resume_file");
    // const fileSize = file.files[0].size / 1024;

    // if (fileSize > 1000) {
    //   alert("Please upload file less than 1 MB");
    //   return;
    // }

   const res = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    }).then(res => res.json())

    if (res.success) {
      openNotificationWithIcon("success",res.msg)
      form.reset(); // Reset the form here after successful submission
      setLoading(false);
    }else{
      openNotificationWithIcon("error",res.msg)
      setLoading(false);
    }

  };
  return (
    <>
    {contextHolder}
    <form id="myForm" onSubmit={onSubmit}>
      <div className="row gx-3 gy-4">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">* Your Name</label>
            <input
              name="name"
              placeholder="Name"
              className="form-control"
              type="text"
              
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">* Your Email</label>
            <input
              name="email"
              placeholder="Email"
              className="form-control"
              type="email"
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">linkedin URL</label>
            <input
              name="linkedin_URL"
              placeholder="linkedin url"
              className="form-control"
              type="text"
            />
          </div>
        </div>
        <div className="col-md-6">

          <div className="form-group">
            <label className="form-label">Resume link</label>
            <input
              name="gDrive_link"
              placeholder="link url"
              className="form-control"
              type="text"
            />
          </div>
        </div>
        {/* <div className="col-12">
          <div className="form-group">
            <label className="form-label">Resume</label>
            <input
              name="resume"
              placeholder="resume *"
              className="form-control"
              type="file"
              id="resume_file"
              required
            />
          </div>
        </div> */}
        <div className="col-md-12">
          <div className="send">
            <button
              className={`px-btn w-100 ${loading ? 'disabled' : ''}`}
              type="submit"
            >
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </div>
      </div>
    </form>
    </>
  );
}
